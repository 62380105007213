html {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
}

.App-header {
  background-color: #FFE666;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #5d4037;
}

.container {
  margin: 20px;
  text-align: left;
  max-width: 500px;
  text-align: justify;
  word-break: keep-all;
}

p {
  line-height: calc(10px + 2vmin);
}